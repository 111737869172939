import { Card, NpcInfo, Scheme, SchemeNode, SchemeNodeTreeStruct } from "@/interface/FfxivDTO";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import CardInfoBox from '@/components/ffxiv/CardInfoBox.vue';
import { formatToTree } from "@/utils/ffxiv-text-tool";

@Component({
  components: {
    CardInfoBox
  },
})
export default class FFXIVNineCardHome extends Vue {

  schemeDialogShow = false;

  checkerboardCardDialogShow = false;

  //-1 尚未开始 1先手，0后手
  onTheOffensive = -1;

  startOfMatch = false;

  npcSelectItem: NpcInfo = {
    id: -1,
    createdAt: new Date(),
    name: "",
    describe: "",
    code: "",
    updatedAt: new Date(),
    position: ""
  };

  schemeHelpText = '';

  npcSelectItemList = [];

  npcCardsList: Card[] = [];

  schemeList: Scheme[] = [];

  schemeCards: Card[] = [];

  totalCards: Card[] = [];

  schemeNodeRelData: SchemeNode[] = [];

  checkerboard: Card[] = [];

  checkerboardCardSelectItems: Card[] = [];

  checkerboardNodeChildren: SchemeNode[] = [];

  checkerSelectIndex = 0;

  checkerboardCardLoading = false;

  exportSchemeCardData: SchemeNode[] = [];
  editModel = false;


  selectSchemeItem: Scheme = {
    id: -1,
    createdAt: new Date(),
    name: "",
    describe: "",
    characterId: 0,
    updatedAt: new Date(),
    code: "",
    author: "",
    priority: 0
  }

  schemeTreeViewData: SchemeNodeTreeStruct = {
    offensive: {
      id: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      name: '',
      cardId: 0,
      schemeId: 0,
      describe: '',
      first: 0,
      parentId: 0,
      cardPosition: 0
    },
    defensive: {
      id: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      name: '',
      cardId: 0,
      schemeId: 0,
      describe: '',
      first: 0,
      parentId: 0,
      cardPosition: 0
    }
  }

  mounted() {
    this.getAllNpcData();
    this.initCheckerBoard();

  }

  checkerboardCardClickEvent(item: Card, index: number) {
    if (item.color == 'light-blue darken-4') {
      this.checkerboardCardDialogShow = true;
      this.checkerSelectIndex = index;
    }
  }

  editModelOpenEvent() {
    this.editModel = true;
    for (let i = 0; i < 9; i++) {
      Vue.set(this.checkerboard, i, {
        id: -1,
        type: 0,
        rarity: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        cardNo: "",
        cardCode: "null-surface",
        name: "-",
        describe: "",
        title: "",
        color: 'light-blue darken-4',
      });
    }
  }

  exportSchemeCardDataEvent() {
    console.log(this.exportSchemeCardData);

  }

  initCheckerBoard() {
    const initData: Card[] = [];
    const tempCardInfo: Card = {
      id: -1,
      type: 0,
      rarity: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      cardNo: "",
      cardCode: "null-surface",
      name: "-",
      describe: "",
      title: ""
    }
    for (let i = 0; i < 9; i++) {
      initData.push(tempCardInfo);
    }
    this.checkerboard = initData;
  }

  selectOnTheOffensive(isOffensive: boolean) {
    this.onTheOffensive = isOffensive ? 1 : 0;
    this.startOfMatch = true;
    this.schemeHelpText = '该案例没有找到后续节点，可能该情况超出预料范围内，请自行解决。点击重置对局可以重新开始。';
    let schemeNodes: SchemeNode[];
    if (isOffensive) {
      this.schemeHelpText = '你先手。请选择蓝色区域，选择你放入的卡牌。';
      schemeNodes = this.schemeTreeViewData.offensive.children!;
      const temp: SchemeNode = {
        id: -1,
        createdAt: new Date(),
        updatedAt: new Date(),
        name: this.schemeTreeViewData.offensive.name,
        cardId: -1,
        schemeId: this.schemeTreeViewData.offensive.schemeId,
        describe: "",
        first: this.schemeTreeViewData.offensive.first,
        parentId: this.schemeTreeViewData.offensive.parentId,
        cardPosition: this.schemeTreeViewData.offensive.cardPosition
      }
      this.exportSchemeCardData.push(temp);
    } else {
      this.schemeHelpText = '你后手。请选择蓝色区域，选择对手放入的卡牌。';
      schemeNodes = this.schemeTreeViewData.defensive.children!;
      const temp: SchemeNode = {
        id: -1,
        createdAt: new Date(),
        updatedAt: new Date(),
        name: this.schemeTreeViewData.defensive.name,
        cardId: -1,
        schemeId: this.schemeTreeViewData.defensive.schemeId,
        describe: "",
        first: this.schemeTreeViewData.defensive.first,
        parentId: this.schemeTreeViewData.defensive.parentId,
        cardPosition: this.schemeTreeViewData.defensive.cardPosition
      }
      this.exportSchemeCardData.push(temp);
    }
    this.checkerboardNodeChildren = schemeNodes;
    let cards: Card[] = [];
    for (let i = 0; i < schemeNodes.length; i++) {
      const item = this.totalCards.filter((item: Card) => { return item.id == schemeNodes[i].cardId })[0];
      Vue.set(this.checkerboard, schemeNodes[i].cardPosition - 1, {
        id: -1,
        type: 0,
        rarity: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        cardNo: "",
        cardCode: "null-surface",
        name: "-",
        describe: "",
        title: "",
        color: 'light-blue darken-4',
      });
      cards.push(item);
    }
    cards = Array.from(new Set(cards));
    this.checkerboardCardSelectItems = cards;
  }

  checkerboardCardSelectEvent(item: Card) {
    item.color = 'grey darken-4';

    this.checkerboardCardDialogShow = false;
    Vue.set(this.checkerboard, this.checkerSelectIndex, item);
    this.checkerboard.forEach((card: Card) => {
      card.color = '';
    })
    const children = this.checkerboardNodeChildren.filter((schemeNode: SchemeNode) => { return schemeNode.cardId == item.id && schemeNode.cardPosition == (this.checkerSelectIndex + 1) });
    if (children.length == 0) {
      this.schemeHelpText = '该案例没有找到后续节点，可能该情况超出预料范围内，请自行解决。点击重置对局可以重新开始。';
      return;
    }



    const schemeNode = children[0];
    if (schemeNode.children != undefined) {
      let cards: Card[] = [];
      schemeNode.children?.forEach((schemeNodeItem: SchemeNode) => {
        const card = this.totalCards.filter((cardItem: Card) => { return cardItem.id == schemeNodeItem.cardId })[0];
        cards.push(card);
        Vue.set(this.checkerboard, schemeNodeItem.cardPosition - 1, {
          id: -1,
          type: 0,
          rarity: 0,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          cardNo: "",
          cardCode: "null-surface",
          name: "-",
          describe: "",
          title: "",
          color: 'light-blue darken-4',
        });
      });

      cards = Array.from(new Set(cards));

      this.checkerboardCardSelectItems = cards;
      this.checkerboardNodeChildren = schemeNode.children;

      const temp: SchemeNode = {
        id: -1,
        createdAt: new Date(),
        updatedAt: new Date(),
        name: item.name + " " + (this.checkerSelectIndex + 1),
        cardId: item.id,
        schemeId: this.selectSchemeItem.id,
        describe: "",
        first: -1,
        parentId: schemeNode.parentId,
        cardPosition: this.checkerSelectIndex + 1
      }
      this.exportSchemeCardData.push(temp);
    }
  }



  resetGame() {
    this.onTheOffensive = -1;
    this.startOfMatch = false;
    this.initCheckerBoard();
  }


  selectSchemeEvent(item: Scheme) {
    this.selectSchemeItem = item;
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/scheme/cards/query/id',
      method: 'put',
      data: {
        id: item.id
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.schemeCards = response.data.data;
        _this.totalCards = _this.schemeCards.concat(_this.npcCardsList);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });

    _this.$axios({
      url: '/api/v1/ffxiv/scheme/node/query/id',
      method: 'put',
      data: {
        id: item.id
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.schemeNodeRelData = response.data.data;
        _this.schemeTreeViewData.offensive = response.data.data.filter((item: SchemeNode) => item.first === 1)[0];
        _this.schemeTreeViewData.defensive = response.data.data.filter((item: SchemeNode) => item.first === 0)[0];


        _this.schemeNodeTreeDataHandler();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  schemeNodeTreeDataHandler() {
    const [...tempList] = this.schemeNodeRelData;

    const offensiveChildren = formatToTree(tempList as any, this.schemeTreeViewData.offensive.id);
    const defensiveChildren = formatToTree(tempList as any, this.schemeTreeViewData.defensive.id);

    this.schemeTreeViewData.offensive.children = offensiveChildren;
    this.schemeTreeViewData.defensive.children = defensiveChildren;

  }

  getNpcCardsData() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/npc/cards/query/id',
      method: 'put',
      data: {
        id: _this.npcSelectItem.id
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.npcCardsList = response.data.data;

      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  @Watch("npcSelectItem")
  npcSelectItemEvent() {
    this.getNpcCardsData();
    this.selectNpcItemEvent();
  }

  getAllNpcData() {
    const _this = this;
    _this
      .$axios({
        url: '/api/v1/ffxiv/npc',
        method: "get",
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.npcSelectItemList = response.data.data;
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  selectNpcItemEvent() {
    const _this = this;
    _this
      .$axios({
        url: '/api/v1/ffxiv/scheme/query/npc/id',
        method: "put",
        data: {
          id: _this.npcSelectItem.id
        }
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.schemeList = response.data.data;
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });

    this.getNpcCardsData();
  }






}